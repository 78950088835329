import { render, staticRenderFns } from "./orderDS.vue?vue&type=template&id=0457639c&scoped=true&"
import script from "./orderDS.vue?vue&type=script&lang=js&"
export * from "./orderDS.vue?vue&type=script&lang=js&"
import style0 from "./orderDS.vue?vue&type=style&index=0&id=0457639c&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0457639c",
  null
  
)

export default component.exports